
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import * as StatServices from '@/services/StatServices'
import * as FactCheckServices from '@/services/FactCheckService';
import { Partner } from "@/types";

@Component
export default class InteractionsPerPartnerChart extends Vue {
  @Prop({ required: true }) from!: Date;
  @Prop({ required: true }) to!: Date;

  loading = false;
  data = [] as Array<{Partner: string, CLICK: number, CLICK_TROUGH: number}>
  partners = {} as Record<string, Partner>;

  @Watch('from', { immediate: true })
  @Watch('to')
  async onDateChange() {
    this.loading = true;
    const data = await StatServices.ListInteractionsPerPartner(this.from, this.to)
    let partners = await FactCheckServices.GetPartners();
    this.data = []
    for(let partnerDataRow of data){
      let partner = partners.find(p => p.id == partnerDataRow.Partner);

      if(partner && partner.name !== 'UNKNOWN'){
        this.data.push({
          Partner: partner.name,
          CLICK: partnerDataRow['CLICK'],
          CLICK_TROUGH: partnerDataRow['CLICK_TROUGH']
        });
      }
    }
    this.$forceUpdate();
    this.loading = false;
  }
}
